import Form from "../../../components/inputs/Form";
import FormLabel from "../../../components/inputs/FormLabel";
import TextField from "../../../components/inputs/TextFields";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../../hooks/useAuth";
import { axios } from "../../../../hooks/useAxiosPrivate";
import { useEffect } from "react";

const Login = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const { login, setAuthErrorMessage } = useAuth();
    const onSubmit = async (formData) => {
        try {
            const { data } = await axios.post("/api/user/login", formData);
            if (data) {
                login(data);
            }
        } catch (err) {
            if (err.hasOwnProperty("response")) {
                setAuthErrorMessage(err.response.data);

            } else {
                setAuthErrorMessage(err.message);
            }
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FormLabel label="Login" />
            <TextField
                id="userName"
                {...register("userName", { required: "Username is required" })}
                label="Username"
            />
            {errors?.userName?.message && <p className="text-red-500 text-sm">{errors.userName.message}</p>}
            <TextField
                id="password"
                {...register("password", { required: "Password is required" })}
                label="Password"
                type="password"
            />
            {errors?.password?.message && <p className="text-red-500 text-sm">{errors.password.message}</p>}
            <button className="bg-indigo-500 text-white" type="submit">
                Login
            </button>
        </Form>
    );
};

export default Login;
