import { createContext, useCallback, useMemo, useState } from "react";

export const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  const [message, setMessage] = useState("");

  const clearMessage = useCallback(() => setMessage(""), [setMessage]);

  const value = useMemo(
    () => ({
      message,
      setMessage,
      clearMessage,
    }),
    [message, setMessage, clearMessage]
  );

  return (
    <MessageContext.Provider value={value}>{children}</MessageContext.Provider>
  );
};
