import Form from "../../../components/inputs/Form";
import FormLabel from "../../../components/inputs/FormLabel";
import TextField from "../../../components/inputs/TextFields";
import { useForm } from "react-hook-form";
import { Axios } from "../../../../utils/config";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../../../../hooks/useAuth";

const Register = () => {
    const { setAuthErrorMessage } = useAuth();
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate();
    const onSubmit = async (formData) => {
        try {
            const { data } = await Axios.post("/api/user/create", formData);
            console.log(data);
            if (data) {
                navigate("/auth/login");
            }
        } catch (err) {
            if (err.hasOwnProperty("response")) {
                setAuthErrorMessage(err.response.data);
            } else {
                setAuthErrorMessage(err.message);
            }
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FormLabel label="Register" />
            <TextField
                id="firstName"
                {...register("firstName", { required: "First name is required" })}
                label="First Name"
            />
            {errors?.firstName?.message && <p className="text-red-500 text-sm">{errors.firstName.message}</p>}
            <TextField
                id="lastName"
                {...register("lastName", { required: "Last name is required" })}
                label="Last Name"
            />
            {errors?.lastName?.message && <p className="text-red-500 text-sm">{errors.lastName.message}</p>}
            <TextField
                id="userName"
                {...register("userName", { required: "Username is required" })}
                label="Username"
            />
            {errors?.userName?.message && <p className="text-red-500 text-sm">{errors.userName.message}</p>}

            <TextField
                id="email"
                {...register("email", { required: "Email is required" })}
                label="Email"
                type="email"
            />
            {errors?.email?.message && <p className="text-red-500 text-sm">{errors.email.message}</p>}

            <TextField
                id="password"
                {...register("password", { required: "Password is required" })}
                label="Password"
                type="password"
            />
            {errors?.password?.message && <p className="text-red-500 text-sm">{errors.password.message}</p>}

            <TextField
                id="confirmPassword"
                {...register("confirmPassword", {
                    required: "Please retype password again",
                    validate: (value) => {
                        const { password } = getValues();
                        return value === password || "Passwords don't match";
                    },
                })}
                label="Confirm Password"
                type="password"
            />
            {errors?.confirmPassword?.message && <p className="text-red-500 text-sm">{errors.confirmPassword.message}</p>}
            <button className="bg-indigo-500 text-white" type="submit">
                Register
            </button>
        </Form>
    );
};

export default Register;
