import { FaTimes } from "react-icons/fa";
import { toTitleCase } from "../../../utils/helperFunctions";

const Snackbar = ({ message, id, type, removeError }) => (
  <div
    className={`${
      type === "error"
        ? "bg-red-100 border-red-400 text-red-700"
        : "bg-green-100 border-green-400 text-green-700"
    } border p-3 rounded inline-flex items-center mt-3 w-[25rem]`}
    role="alert"
  >
    <strong className="font-bold">{toTitleCase(type)}</strong>
    <span className="block sm:inline px-1">{message}</span>
    <button className="ml-auto" onClick={() => removeError(id)}>
      <FaTimes />
    </button>
  </div>
);

export default Snackbar;
