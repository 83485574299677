import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useConfig } from "../../../hooks/useConfig";
import UserConfigForm from "./UserConfigForm";

const UserConfig = () => {
  const { userId } = useParams();
  const { configs, getConfigs } = useConfig();
  const { isAuthenticated } = useAuth();
  const [userConfig, setUserConfig] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      getConfigs();
    }
  }, [getConfigs, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      const uc = configs.find((c) => c.userId === userId) || null;
      setUserConfig(uc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs, isAuthenticated]);

  return <UserConfigForm config={userConfig} />;
};

export default UserConfig;
