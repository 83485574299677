import { createContext, useCallback, useMemo, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
    const [config, setConfig] = useState({});
    const [configs, setConfigs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [allLoading, setAllLoading] = useState(true);
    const [error, setError] = useState("");
    const [allError, setAllError] = useState("");

    const axiosPrivate = useAxiosPrivate();
    // call this function when you want to authenticate the user
    const getConfig = useCallback(async () => {
        try {
            const { data } = await axiosPrivate.get("/api/config/me");
            setConfig(data);
            return "Successfully loaded config!!"
        } catch (err) {
            if (err.hasOwnProperty("response")) {
                setError(err.response.data.message);
            } else {
                setError(err.message);
            }
        } finally {
            setLoading(false);
        }
    }, [setConfig, axiosPrivate]);

    const getConfigs = useCallback(async () => {
        try {
            const { data } = await axiosPrivate.get("/api/config/get-all");
            setConfigs(data);
            return "Successfully loaded all config!!"
        } catch (err) {
            if (err.hasOwnProperty("response")) {
                setAllError(err.response.data.message);
            } else {
                setAllError(err.message);
            }
        } finally {
            setAllLoading(false);
        }
    }, [setConfigs, axiosPrivate])

    // call this function to sign out logged in user
    const clearConfig = useCallback(() => setConfig({}), [setConfig]);

    const getAccessToken = useCallback(
        async (configId) => {
            try {
                const { data } = await axiosPrivate.post(
                    `/api/config/${configId}/get-access-token`
                );
                if (data) {
                    const msg = getConfig();
                    return msg;
                }
            } catch (err) {
                if (err.hasOwnProperty("response")) {
                    setError(err.response.data);
                } else {
                    setError(err.message);
                }
            }
        },
        [axiosPrivate, getConfig]
    );

    const fetchDevices = useCallback(
        async (configId) => {
            try {
                const { data } = await axiosPrivate.post(
                    `/api/config/${configId}/fetch_devices`
                );
                if (data) {
                    getConfig();
                    return `${data?.total || ""} Devices Loaded`;
                }
            } catch (err) {
                if (err.hasOwnProperty("response")) {
                    setError(err.response.data);
                } else {
                    setError(err.message);
                }
            }
        },
        [axiosPrivate, getConfig]
    );

    const clearConfigsError = useCallback(() => setError(""), [setError]);
    const clearAllConfigsError = useCallback(() => setAllError(""), [setAllError]);

    const value = useMemo(
        () => ({
            config,
            getConfig,
            clearConfig,
            getAccessToken,
            configLoading: loading,
            fetchDevices,
            configsError: error,
            clearConfigsError,
            configs,
            getConfigs,
            allLoading,
            allError,
            clearAllConfigsError
        }),
        [
            config,
            getConfig,
            clearConfig,
            getAccessToken,
            fetchDevices,
            loading,
            error,
            clearConfigsError,
            configs,
            getConfigs,
            allLoading,
            allError,
            clearAllConfigsError
        ]
    );

    return (
        <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
    );
};
