import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useUser } from "../../../hooks/useUser";
/* import { useConfig } from "../../../hooks/useConfig" */

const UsersTable = () => {
  const { users, userLoading, getUsers } = useUser();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      getUsers();
    }
  }, [getUsers, isAuthenticated]);
  console.log(users);
  return (
    <div className="w-full mt-6">
      <div className="border border-gray-200 shadow">
        <table class="table-auto border-collapse w-full">
          <thead className="bg-indigo-600">
            <tr>
              <th className="px-6 py-3 text-xs text-slate-100 text-left">
                First name
              </th>
              <th className="px-6 py-3 text-xs text-slate-100 text-left">
                Last name
              </th>
              <th className="px-6 py-3 text-xs text-slate-100 text-left">
                User name
              </th>
              <th className="px-6 py-3 text-xs text-slate-100 text-left">
                Email
              </th>
              <th className="px-6 py-3 text-xs text-slate-100 text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {!userLoading &&
              users.map((user) => (
                <tr key={user._id}>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {user.firstName}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {user.lastName}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {user.userName}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {user.email}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    <Link
                      to={user._id}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="bg-slate-200 font-medium text-syncoria transition rounded-md ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 ml-3">
                        Show config
                      </button>
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default UsersTable;
