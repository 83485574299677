import {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useLocalStorage("CC_USER", null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authMessage, setAuthMessage] = useState("");
    const [authErrorMessage, setAuthErrorMessage] = useState("");

    const navigate = useNavigate();

    // call this function when you want to authenticate the user
    const login = useCallback(
        (data) => {
            setUser(data);
            navigate("/");
        },
        [setUser, navigate]
    );

    // call this function to sign out logged in user
    const logout = useCallback(() => {
        setUser(null);
        setIsAuthenticated(false);
        navigate("/auth/login", { replace: true });
    }, [setUser, navigate]);

    const clearAuthMessage = useCallback(
        () => setAuthMessage(""),
        [setAuthMessage]
    );
    const clearAuthErrorMessage = useCallback(
        () => setAuthErrorMessage(""),
        [setAuthErrorMessage]
    );

    const value = useMemo(
        () => ({
            user,
            login,
            logout,
            isAuthenticated,
            authMessage,
            authErrorMessage,
            setAuthMessage,
            setAuthErrorMessage,
            clearAuthMessage,
            clearAuthErrorMessage,
        }),
        [
            user,
            login,
            logout,
            isAuthenticated,
            authMessage,
            authErrorMessage,
            setAuthMessage,
            setAuthErrorMessage,
            clearAuthMessage,
            clearAuthErrorMessage,
        ]
    );

    useEffect(() => {
        if (user && user?.token) {
            setIsAuthenticated(true);
        }
    }, [user]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
