import { Outlet } from "react-router-dom";
import Navbar from "../navbar";
import MessageStack from "./MessageStack";

const Layout = () => {
    return (
        <div className="skew-y-[10deg] min-h-screen">
            <Navbar />
            <div className="container mx-auto transition-all duration-200 delay-180 ease-in-out relative">
                <Outlet />
            </div>
            <MessageStack />
        </div>
    );
};

export default Layout;
