import { useEffect, useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { useConfig } from "../../../hooks/useConfig";
import { useDevice } from "../../../hooks/useDevice";
import { useMessage } from "../../../hooks/useMessage";
import { useUser } from "../../../hooks/useUser";
import Snackbar from "./Snackbar";

const MessageStack = () => {
    const [allErrors, setAllErrors] = useState([]);
    const { configsError, clearConfigsError } = useConfig();
    const { usersError, clearUsersError } = useUser();
    const { devicesError, clearDevicesError } = useDevice();
    const { authErrorMessage, clearAuthErrorMessage } = useAuth();
    const { message, clearMessage } = useMessage();
    useEffect(() => {
        const id = Number(Date.now());
        const timeout = 3000;

        if (configsError !== "") {
            setAllErrors((errs) => [
                ...errs,
                { id: id, message: configsError, type: "error" },
            ]);
            clearConfigsError();
            setTimeout(() => {
                setAllErrors((errs) => errs.filter((err) => err.id !== id));
            }, timeout);
        }
    }, [configsError, setAllErrors, clearConfigsError]);

    useEffect(() => {
        const id = Number(Date.now());
        const timeout = 3000;

        if (usersError !== "") {
            setAllErrors((errs) => [
                ...errs,
                { id: id, message: usersError, type: "error" },
            ]);
            clearUsersError();
            setTimeout(() => {
                setAllErrors((errs) => errs.filter((err) => err.id !== id));
            }, timeout);
        }
    }, [usersError, setAllErrors, clearUsersError]);
    useEffect(() => {
        const id = Number(Date.now());
        const timeout = 3000;

        if (devicesError !== "") {
            setAllErrors((errs) => [
                ...errs,
                { id: id, message: devicesError, type: "error" },
            ]);
            clearDevicesError();
            setTimeout(() => {
                setAllErrors((errs) => errs.filter((err) => err.id !== id));
            }, timeout);
        }
    }, [devicesError, setAllErrors, clearDevicesError]);
    useEffect(() => {
        const id = Number(Date.now());
        const timeout = 3000;

        if (authErrorMessage !== "") {
            setAllErrors((errs) => [
                ...errs,
                { id: id, message: authErrorMessage, type: "error" },
            ]);
            clearAuthErrorMessage();
            setTimeout(() => {
                setAllErrors((errs) => errs.filter((err) => err.id !== id));
            }, timeout);
        }
    }, [authErrorMessage, setAllErrors, clearAuthErrorMessage]);
    useEffect(() => {
        const id = Number(Date.now());
        const timeout = 3000;

        if (message !== "") {
            setAllErrors((errs) => [...errs, { id: id, message, type: "success" }]);
            clearMessage();
            setTimeout(() => {
                setAllErrors((errs) => errs.filter((err) => err.id !== id));
            }, timeout);
        }
    }, [setAllErrors, message, clearMessage]);

    const removeError = (id) => {
        setAllErrors((errs) => errs.filter((err) => err.id !== id));
    };

    return (
        <div className="flex flex-col absolute bottom-3 left-3">
            {allErrors.map(({ message, id, type }) => (
                <Snackbar
                    message={message}
                    key={id}
                    removeError={(idx) => removeError(idx)}
                    type={type}
                />
            ))}
        </div>
    );
};

export default MessageStack;
